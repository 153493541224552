export const STORAGE_KEYS = {
  APP_LANGUAGE: "appLanguage",
};

export const APP_LANGUAGES_CODE = {
  AM: "am",
  EN: "en",
  RU: "ru",
};

export const APP_LANGUAGES = [
  {
    id: 1,
    key: "am",
    code: "am",
    name: "Հայ",
  },
  {
    id: 2,
    key: "en",
    code: "en",
    name: "En",
  },
  {
    id: 3,
    key: "ru",
    code: "ru",
    name: "Рус",
  },
];
