import React from "react";
import { Anchor } from "antd";
import { useTranslation } from "react-i18next";
import bgTree from "../../assets/images/tree-green.png";

const { Link } = Anchor;

function Hero() {
  const { t } = useTranslation();

  return (
    <div id="hero" className="container-fluid">
      <div className="heroBlock">
        <div className="content">
          <p className="slogan">{t("slogan")}</p>
          <div className="btnHolder">
            <Anchor targetOffset="200">
              <Link
                href="#contact"
                className="mainButton"
                title={t("menu.contact")}
              />
              <Link
                href="/Identity/Account/Login"
                className="logInBtn"
                title={t("menu.login")}
                target="_blanck"
              />
            </Anchor>
          </div>
        </div>
        <div className="heroBg">
          <img src={bgTree} alt="headerBg" />
        </div>
      </div>
    </div>
  );
}

export default Hero;
