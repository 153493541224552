import React from "react";
import { Layout } from "antd";
import {
  Header,
  Hero,
  AboutUs,
  Devices,
  Partners,
  Contacts,
  Footer,
} from "./components";

function App() {
  return (
    <Layout className="mainLayout">
      <Header />
      <Layout.Content>
        <Hero />
        <AboutUs />
        <Devices />
        <Partners />
        <Contacts />
      </Layout.Content>
      <Footer />
    </Layout>
  );
}

export default App;
