import React from "react";
import { Divider } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
// import {MdArrowRightAlt} from "react-icns/md"

// Import Swiper styles
// import "swiper/css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper";
import { useTranslation } from "react-i18next";

import SwiperCore, { Autoplay } from "swiper";

import image1 from "../../assets/images/logo1.png";
import image2 from "../../assets/images/logo2.png";
import image3 from "../../assets/images/logo3.png";
import image4 from "../../assets/images/logo4.png";
import image11 from "../../assets/images/logo1.png";
import image22 from "../../assets/images/logo2.png";
import image33 from "../../assets/images/logo3.png";
import image44 from "../../assets/images/logo4.png";

// install Swiper modules
SwiperCore.use([Autoplay]);

const Partners = () => {
  const { t } = useTranslation();
  return (
    <>
      <Divider className="blockTitle"> {t("partners")} </Divider>
      <div id="partners" className="block partnersBlock">
        <div className="container">
          <Swiper
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={true}
            // slidesPerView={4}
            spaceBetween={10}
            // freeMode={true}
            modules={[Pagination, Navigation]}
            // className="mySwiper"
            breakpoints={{
              480: {
                slidesPerView: 3,
              },
              500: {
                slidesPerView: 3,
              },
              700: {
                slidesPerView: 3,
              },
              1920: {
                slidesPerView: 4,
              },
            }}
          >
            <SwiperSlide>
              <a href="#" className="partnerLogo">
                <img alt="Modern Design" src={image1} />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#" className="partnerLogo">
                <img alt="Modern Design" src={image2} />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#" className="partnerLogo">
                <img alt="Modern Design" src={image4} />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#" className="partnerLogo">
                <img alt="Modern Design" src={image3} />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#" className="partnerLogo">
                <img alt="Modern Design" src={image11} />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#" className="partnerLogo">
                <img alt="Modern Design" src={image22} />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#" className="partnerLogo">
                <img alt="Modern Design" src={image33} />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a href="#" className="partnerLogo">
                <img alt="Modern Design" src={image44} />
              </a>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Partners;
