import React from "react";
import { Select } from "antd";
import i18n from "../../localization";
import {
  STORAGE_KEYS,
  APP_LANGUAGES,
  APP_LANGUAGES_CODE,
} from "../../constants";
import { set, get } from "../../helpers/storage";

const { Option } = Select;

const LanguageSwitcher = () => {
  const appLangFromStorage = get(STORAGE_KEYS.APP_LANGUAGE);

  const handleChange = (selectedLanguage) => {
    i18n.changeLanguage(selectedLanguage);
    set(STORAGE_KEYS.APP_LANGUAGE, selectedLanguage);
  };

  return (
    <Select
      defaultValue={APP_LANGUAGES_CODE.EN || appLangFromStorage}
      bordered={false}
      onChange={handleChange}
      className="langsDropdown"
    >
      {APP_LANGUAGES.length &&
        APP_LANGUAGES.map((lang) => (
          <Option key={lang.code} value={lang.code} className="langs">
            {lang.name}
          </Option>
        ))}
    </Select>
  );
};

export default LanguageSwitcher;
