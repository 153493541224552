import React from "react";
import { FloatButton, Layout } from "antd";

import logo from "../../assets/images/logo.png";

function Footer() {
  return (
    <Layout.Footer>
      <div className="container">
        <div className="footer">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="Logo" />
            </a>
          </div>
          <div className="copyright">
            <p>
              <a href="mailto:info@tiotin.com">info@tiotin.com</a>
            </p>
            <p>Copyright &copy; 2023 TIOTIN</p>
          </div>
          <FloatButton.BackTop>
            <div className="goTop">
              <i className="fas fa-arrow-circle-up"></i>
            </div>
          </FloatButton.BackTop>
        </div>
      </div>
    </Layout.Footer>
  );
}

export default Footer;
