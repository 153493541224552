import React from "react";
import { Row, Col, Form, Input, Button, Divider } from "antd";
import { FiFacebook, FiYoutube, FiInstagram, FiLinkedin } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;

function Contacts() {
  const { t } = useTranslation();
  return (
    <>
      <Divider className="blockTitle">{t("contacts.contactsTitle")} </Divider>
      <div id="contact" className="block contactsBlock">
        <div className="container">
          <Row gutter={[16, 16]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <Form
                name="normal_login"
                className="login-form"
                size="large"
                initialValues={{ remember: true }}
              >
                <p className="subTitle"> {t("contacts.writeUs")} </p>
                <Row gutter={10}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name="fullname"
                      rules={[
                        {
                          required: true,
                          message: t("contacts.errorFullName"),
                        },
                      ]}
                    >
                      <Input type="text" placeholder={t("contacts.fullname")} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: t("contacts.errorEmail"),
                        },
                      ]}
                    >
                      <Input
                        type="email"
                        placeholder={t("contacts.emailAddress")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name="telephone"
                      rules={[
                        { required: true, message: t("contacts.errorTel") },
                      ]}
                    >
                      <Input placeholder={t("contacts.tel")} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      name="subject"
                      rules={[
                        {
                          required: true,
                          message: t("contacts.errorFullName"),
                        },
                      ]}
                    >
                      <Input placeholder={t("contacts.subject")} />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item name="message">
                  <TextArea rows={8} placeholder={t("contacts.message")} />
                </Form.Item>

                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="mainButton login-form-button"
                  >
                    {t("contacts.submit")}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <div className="mapBlock">
                <div className="mapouter">
                  <div className="gmap_canvas">
                    <iframe
                      id="gmap_canvas"
                      src="https://maps.google.com/maps?q=armenia%20yerevan%20armen%20armenkyan%20125&t=&z=15&ie=UTF8&iwloc=&output=embed"
                      frameBorder="0"
                      scrolling="no"
                      marginHeight="0"
                      marginWidth="0"
                    ></iframe>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 16, offset: 4 }}
            >
              <div className="contacts-info">
                {/* <p className="subTitle">Զանգահարեք մեզ</p> */}
                {/* <Row gutter={[16, 16]} className="phons">
                  <Col sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 4 }}>
                    <a href="tel:+37493000000">
                      <span className="icon tel">
                        <MdCall />
                      </span>
                      <span>+374 93 00-00-00</span>
                    </a>
                  </Col>
                  <Col sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 4 }}>
                    <a href="#">
                      <span className="icon viber">
                        <FaViber />
                      </span>
                      <span>+374 93 00-00-00</span>
                    </a>
                  </Col>
                  <Col sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 4 }}>
                    <a href="#">
                      <span className="icon telegram">
                        <FaTelegramPlane />
                      </span>
                      <span>+374 93 00-00-00</span>
                    </a>
                  </Col>
                  <Col sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 4 }}>
                    <a href="#">
                      <span className="icon whatsapp">
                        <FaWhatsapp />
                      </span>
                      <span>+374 93 00-00-00</span>
                    </a>
                  </Col>
                  <Col sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 4 }}>
                    <a href="#">
                      <span className="icon email">
                        <FiMail />
                      </span>
                      <span>tiotincompany@gmail.com</span>
                    </a>
                  </Col>
                </Row> */}
                <Row gutter={[16, 16]} className="socialBlock">
                  <Col
                    xs={{ span: 6 }}
                    sm={{ span: 6 }}
                    md={{ span: 6 }}
                    lg={{ span: 6 }}
                  >
                    <a
                      href="https://www.facebook.com/tiotinofficial"
                      target="_blank"
                      className="icon facebook"
                    >
                      <FiFacebook />
                    </a>
                  </Col>
                  <Col
                    xs={{ span: 6 }}
                    sm={{ span: 6 }}
                    md={{ span: 6 }}
                    lg={{ span: 6 }}
                  >
                    <a
                      href="https://www.youtube.com/@tiotinoffical"
                      target="_blank"
                    >
                      <span className="icon youtube">
                        <FiYoutube />
                      </span>
                    </a>
                  </Col>
                  <Col
                    xs={{ span: 6 }}
                    sm={{ span: 6 }}
                    md={{ span: 6 }}
                    lg={{ span: 6 }}
                  >
                    <a
                      href="https://www.linkedin.com/company/tiotin/about/"
                      target="_blank"
                    >
                      <span className="icon linkedin">
                        <FiLinkedin />
                      </span>
                    </a>
                  </Col>
                  <Col
                    xs={{ span: 6 }}
                    sm={{ span: 6 }}
                    md={{ span: 6 }}
                    lg={{ span: 6 }}
                  >
                    <a
                      href="https://www.instagram.com/tiotinofficial/"
                      target="_blank"
                    >
                      <span className="icon instagram">
                        <FiInstagram />
                      </span>
                    </a>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Contacts;
