import React, { useState } from "react";
// import Video from "../Video";
import { Row, Col, List, Divider } from "antd";
import toolsBg from "../../assets/images/tools-bg2.png";
import tools4 from "../../assets/images/tools4.png";
import tools2 from "../../assets/images/tools2.png";
import tools3 from "../../assets/images/tools3.png";
import { useTranslation } from "react-i18next";

const Devices = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line
  const [activeTab, _] = useState(1);

  // const handelClick = (key) => () => setActiveTab(key);

  const data = [
    {
      id: 1,
      key: 1,
      description: t("devices.one"),
      count: (
        <div className="deviceIcon">
          <img src={tools2} alt="device1" />
        </div>
      ),
      class: "one",
    },
    {
      id: 2,
      key: 2,
      description: t("devices.two"),
      count: (
        <div className="deviceIcon">
          <img src={tools3} alt="device2" />
        </div>
      ),
      class: "two",
    },
    {
      id: 3,
      key: 3,
      description: t("devices.three"),
      count: (
        <div className="deviceIcon">
          <img src={tools4} alt="device3" />
        </div>
      ),
      class: "three",
    },
  ];

  // const renderInstructionContent = (key) => {
  //   switch (key) {
  //     case 1:
  //       return (
  //         <div className="video">
  //           <Video videoPath="https://youtu.be/unBq9ckXfFs" />
  //         </div>
  //       );
  //     case 2:
  //       return (
  //         <div className="video">
  //           <Video videoPath="https://youtu.be/lO6AZ0g3aww" />
  //         </div>
  //       );
  //     case 3:
  //       return (
  //         <div className="video">
  //           <Video videoPath="https://youtu.be/wJxpfJYcxbU" />
  //         </div>
  //       );
  //     default:
  //       return null;
  //   }
  // };

  return (
    <>
      <Divider className="blockTitle">{t("devices.title")} </Divider>
      <div id="devices" className="block devicesBlock">
        <div className="container">
          <Row gutter={[16, 16]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <div className="devicesBg">
                <img src={toolsBg} alt="deviceBg" />
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item, id) => (
                  <List.Item
                    // onClick={handelClick(item.key)}
                    className={item.id === id ? "active" : ""}
                  >
                    <List.Item.Meta
                      avatar={item.count}
                      title={item.title}
                      className={item.class}
                      description={<p>{item.description}</p>}
                    />
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Devices;
