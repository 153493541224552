import React from "react";
import { Row, Col, Divider } from "antd";
import { useTranslation } from "react-i18next";
import icon1 from "../../assets/images/about-icon1.png";
import icon2 from "../../assets/images/about-icon2.png";
import icon3 from "../../assets/images/about-icon3.png";
import aboutUsBg from "../../assets/images/about-us.png";

function AboutUs() {
  const { t } = useTranslation();

  const items = [
    {
      key: "1",
      icon: <img src={icon3} alt="security" />,
      title: t("about.cardOneTitle"),
      content: t("about.cardOneText"),
      cardStyle: "cardOneStyle",
    },
    {
      key: "2",
      icon: <img src={icon2} alt="availability" />,
      title: t("about.cardTwoTitle"),
      content: t("about.cardTwoText"),
      cardStyle: "cardTwoStyle",
    },
    {
      key: "3",
      icon: <img src={icon1} alt="innovation" />,
      title: t("about.cardTreeTitle"),
      content: t("about.cardTreeText"),
      cardStyle: "cardTreeStyle",
    },
  ];

  return (
    <>
      <Divider className="blockTitle">{t("about.title")}</Divider>
      <div id="about" className="aboutBlock">
        <div className="container">
          <div className="contentHolder aboutTextBlock">
            <img className="aboutUsBg" src={aboutUsBg} alt="about" />
            <p>{t("about.text")}</p>
          </div>
          <Divider className="blockTitle">{t("about.ourAdvantages")}</Divider>
          <div id="advantages" className="block">
            <Row gutter={[16, 16]}>
              {items.map((item) => {
                return (
                  <Col key={item.key} md={{ span: 24 }} lg={{ span: 8 }}>
                    <div className={`content aboutCard ${item.cardStyle}`}>
                      <div className="cardTitleBlock">
                        <div className="icon">{item.icon}</div>
                      </div>
                      <div>
                        <h3>{item.title}</h3>
                        <p>{item.content}</p>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
