import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { APP_LANGUAGES_CODE } from "../constants";

import en from "./en.json";
import am from "./am.json";
import ru from "./ru.json";

const resources = {
  en: { translation: en },
  am: { translation: am },
  ru: { translation: ru },
};

// if (!localStorage.getItem(STORAGE_KEYS.APP_LANGUAGE)) {
//   localStorage.setItem(STORAGE_KEYS.APP_LANGUAGE, APP_LANGUAGES_CODE.EN);
// }
// set(STORAGE_KEYS.APP_LANGUAGE, APP_LANGUAGES_CODE.EN);
// const appLanguage = get(STORAGE_KEYS.APP_LANGUAGE);

i18n.use(initReactI18next).init({
  resources,
  lng: APP_LANGUAGES_CODE.EN,
  fallbackLng: APP_LANGUAGES_CODE.EN,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
