import React, { useState, useEffect } from "react";
import { Anchor, Drawer, Button, Layout } from "antd";
import i18n from "../../localization";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../LanguageSwitcher";
import { STORAGE_KEYS } from "../../constants";
import { set } from "../../helpers/storage";
import {
  FiHome,
  FiInfo,
  FiStar,
  FiBook,
  FiUsers,
  FiAtSign,
} from "react-icons/fi";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import logo from "../../assets/images/logo.png";

const { Link } = Anchor;

const AppHeader = () => {
  const { t } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState("en");
  const [visible, setVisible] = useState(false);
  const [navbarBgColor, setNavbarBgColor] = useState(false);

  const handleChange = (selectedLanguage) => () => {
    setActiveLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    set(STORAGE_KEYS.APP_LANGUAGE, selectedLanguage);
  };

  const changeNavbarColor = () => setNavbarBgColor(window.scrollY >= 10);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);

  return (
    <Layout.Header className={navbarBgColor ? "colored" : ""}>
      <div className="container">
        <div className="header">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="Logo" />
            </a>
          </div>
          <div className="mobileHidden">
            <Anchor targetOffset="200">
              <Link href="#hero" title={t("menu.home")} />
              <Link href="#about" title={t("menu.about")} />
              <Link href="#advantages" title={t("menu.ourAdvantages")} />
              <Link href="#devices" title={t("menu.devices")} />
              <Link href="#partners" title={t("menu.partners")} />
              <Link href="#contact" title={t("menu.contact")} />
              <div className="headerLoginBtnBox">
                <a
                  href="/Identity/Account/Login"
                  className="headerLoginBtn"
                  target="_blanck"
                >
                  {t("menu.login")}
                </a>
              </div>
              <LanguageSwitcher />
            </Anchor>
          </div>

          <div className="mobileVisible">
            <Button className="mobileMenuIcon" onClick={showDrawer}>
              <MenuFoldOutlined />
            </Button>
            <Drawer
              placement="right"
              closable={false}
              onClose={onClose}
              open={visible}
              className="mobileMenu"
            >
              <div>
                <Button className="mobileMenuIcon" onClick={onClose}>
                  <MenuUnfoldOutlined />
                </Button>
                <Anchor targetOffset="200" onClick={onClose}>
                  <Link
                    href="#hero"
                    title={t("menu.home")}
                    className="mobileMenuItem hero"
                  >
                    <FiHome />
                  </Link>
                  <Link
                    href="#about"
                    title={t("menu.about")}
                    className="mobileMenuItem"
                  >
                    <FiInfo />
                  </Link>
                  <Link
                    href="#advantages"
                    title={t("menu.ourAdvantages")}
                    className="mobileMenuItem"
                  >
                    <FiStar />
                  </Link>
                  <Link
                    href="#devices"
                    title={t("menu.devices")}
                    className="mobileMenuItem devices"
                  >
                    <FiBook />
                  </Link>
                  <Link
                    href="#partners"
                    title={t("menu.partners")}
                    className="mobileMenuItem"
                  >
                    <FiUsers />
                  </Link>
                  <Link
                    href="#contact"
                    title={t("menu.contact")}
                    className="mobileMenuItem"
                  >
                    <FiAtSign />
                  </Link>
                  <Link
                    href="/Identity/Account/Login"
                    title={t("menu.login")}
                    className="mobileMenuItem logIn"
                    target="_blanck"
                  />
                </Anchor>
              </div>
              <div className="mobileLangs">
                <button
                  key={"en"}
                  onClick={handleChange("en")}
                  className={activeLanguage === "en" ? "en active" : "en"}
                >
                  <span className="langName">En</span>
                </button>
                <button
                  key={"am"}
                  onClick={handleChange("am")}
                  className={activeLanguage === "am" ? "am active" : "am"}
                >
                  <span className="langName">Am</span>
                </button>
                <button
                  key={"ru"}
                  onClick={handleChange("ru")}
                  className={activeLanguage === "ru" ? "ru active" : "ru"}
                >
                  <span className="langName">Ru</span>
                </button>
              </div>
            </Drawer>
          </div>
        </div>
      </div>
    </Layout.Header>
  );
};

export default AppHeader;
